// libs
import get from 'lodash/get';

// constants
import { DESKTOP_MAX_RECENT_SEARCHES } from 'shared/components/GlobalSearch/constants';
import { ERROR_TAGS, TAG_IDENTIFIER } from 'helpers/newRelic/constants';
const MODULE_ID = 'Header-SearchWidget';

// helpers
import { log } from 'shared/helpers/newRelic';

// selectors
import { getRemoteConfig } from 'shared/store/remoteConfigs/selectors';

export const getMaxRecentSearches = (state: any) => {
  let maxRecentSearches = DESKTOP_MAX_RECENT_SEARCHES;

  try {
    const config = getRemoteConfig(state, 'globalSearch');
    const desktopConfig = get(config, 'desktop');
    maxRecentSearches = get(desktopConfig, 'max_recent_suggestions', DESKTOP_MAX_RECENT_SEARCHES);
  } catch (error) {
    log({
      errTitle: 'Error in getMaxRecentSearches',
      error,
      tags: {
        [TAG_IDENTIFIER.ERROR]: ERROR_TAGS.FIREBASE_CONFIG_FAILURE,
        [TAG_IDENTIFIER.MODULE]: MODULE_ID,
      },
    });
  }

  return maxRecentSearches;
};
